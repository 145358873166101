<template>
  <div>
    <b-form-group
      label="Référence de colis"
      label-for="vi-first-name"
    >
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="ref"
          placeholder="Référence de colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <validation-observer ref="simpleRules">
      <!-- table -->
      <div class="d-flex justify-content-between">
        <b-card>
          Nombre : {{ selected.length }}
          <vue-good-table
            :columns="columns"
            :rows="selected"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'id'">
                <span> {{ props.row.id }}</span><br>
                <span
                  v-if="props.row.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="DeleteIcon"
                    color="red"
                    class="cursor-pointer mr-1"
                    size="16"
                    @click="resetItem(props.row)"
                  />
                  <b-tooltip
                    title="Annuler"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>

          </vue-good-table>

          <b-form-group class="d-flex justify-content-end">
            <b-button
              ref="saveButton"
              variant="gradient-primary"
              class="ml-1 d-inline-block"
              @click="addExchangeVoucher"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Enregistrer</span>
            </b-button>
          </b-form-group>
        </b-card>

      </div>
    </validation-observer>
  </div></template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
// import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import ok from '@/assets/audio/ok.mp3'
import notexist from '@/assets/audio/notexist.mp3'
import alreadyScanned from '@/assets/audio/colis-deja-scanne.mp3'

export default {
  components: {
    BButton,
    ValidationObserver,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    // flatPickr,
    BPagination,
    BTooltip,
    BCard,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'code',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher code',
          },
        },
        {
          label: 'nom',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nom',
          },
        },
        {
          label: 'fournisseur',
          field: 'supplier',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher fournisseur',
          },
        },
        {
          label: 'gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
        {
          label: 'prix',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher prix',
          },
        },
      ],
      role: '',
      orders: [],
      cars: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      isLoading: false,
      selectedCar: null,
      ok,
      notexist,
      alreadyScanned,
    }
  },
  created() {
    this.user = storeAuth.state.user
  },
  methods: {
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    exists(id) {
      return this.items.some(el => el.code === Number(id))
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    async checkOrder() {
      try {
        const { data } = await axios.get(`/api/orders/check-exchange/${(this.ref)}`)
        if (data.message === 'OK') {
          if (!this.selected.some(selectedItem => selectedItem.id === data.order.id)) {
            // If it doesn't exist, add it
            this.addItem(data.order).then(() => {
              this.ref = ''
              this.playAudio(ok)
            })
          } else {
            // If it already exists, display a message and play the audio
            this.withTitle('Colis déjà scanné')
            this.playAudio(alreadyScanned)
          }
        } else if (data.message === 'Not Exchange') {
          // Handle the case when order is found but not an exchange
          this.withTitle('Colis non exist')
          this.ref = ''
          this.playAudio(notexist)
        } else if (data.message === 'Not Exist') {
          // Handle the case when order does not exist
          this.withTitle('Colis non exist')
          this.ref = ''
          this.playAudio(notexist)
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    },
    async addExchangeVoucher() {
      if (this.$refs.saveButton.hasAttribute('disabled')) {
        return
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.$refs.saveButton.setAttribute('disabled', 'disabled')
          const orderIds = this.selected.map(order => order.id)
          const payload = {
            orders: orderIds,
          }
          try {
            const { data } = await axios.post('/api/exchange-vouchers/create-exchange-voucher/', payload)
            this.$router.push(`/bons-echanges/show/${data}`)
            this.showToast('success', 'top-center', 'Bon échange ajouté avec succès')
          } catch (error) {
            this.showToast('danger', 'top-center', error.toString())
          } finally {
            this.$refs.saveButton.removeAttribute('disabled')
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'Champ obligatoire ! Vérifiez vos données !')
          }, 1000)
        }
      })
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => element.id === item.id)
      this.orders.splice(i, 1)
    },
    async resetItem(item) {
      this.orders.push(item)
      const i = this.selected.findIndex(element => element.id === item.id)
      this.selected.splice(i, 1)
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
